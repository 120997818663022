


@media (max-width:767px){
    .navbar {
        opacity: 1;
        background-color: rgba(29, 64, 120, 0.7) !important;
    }
    
    .img-brand {
        padding-right: 0;
        margin: 0;
    }
    
    .button-nav {
        margin-right: 0px;
    }
    
    
    
    .header-container-update {
        max-width: 90% !important;
    }
    
    .navigation{
        margin-top: 10% !important;
    }

    .language-switch{
        float: right !important;
        margin-right: auto;
        margin-left: auto;
        margin-top: 15% !important;
        width: 15vh;
        margin-top: 0.5%;
    }
    
    .SwitchButton{
        margin-top: -17%;
    }
    
    .flag-active{
        width: 25%;
        cursor: pointer;
    }
    
    .flag-inactive{
        width: 25%;  
        cursor: pointer;
    }
}

@media (min-width: 768px) and (max-width: 1023px){
    .navbar {
        opacity: 1;
        background-color: rgb(255, 255, 255) !important;
    }
    
    .img-brand {
        padding-right: 0;
        margin: 0;
    }
    
    .button-nav {
        margin-right: 0px;
    }
    
    .nav-butt-container{
        font-size: 12px;

    }
    
    .header-container-update {
        max-width: 100vh !important;
    }
    
    .language-switch{
        float: right !important;
        margin-right: auto;
        margin-left: auto;
        width: 25vh;
        margin-top: 0.5%;
    }
    
    .SwitchButton{
        margin-top: -9%;
    }
    
    .flag-active{
        width: 12% !important;
        cursor: pointer;
    }
    
    .flag-inactive{
        width: 12%;  
        cursor: pointer;
    }
}

@media (min-width: 1024px) {
    .navbar {
        opacity: 1;
        background-color: rgba(29, 64, 120, 0.7) !important;
    }
    
    .img-brand {
        padding-right: 0;
        margin: 0;
    }
    
    .button-nav {
        margin-right: 0px;
    }
    
    
    
    .header-container-update {
        max-width: 90% !important;
    }
    
    .language-switch{
        float: right !important;
        margin-right: -8%;
        margin-left: 1%;
        width: 16vh;
        margin-top: 0.5%;
    }
    
    .SwitchButton{
        margin-top: -17%;
    }
    
    .flag-active{
        width: 20%;
        cursor: pointer;
    }
    
    .flag-inactive{
        width: 20%;  
        cursor: pointer;
    }
}