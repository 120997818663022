@media (max-width: 767px){

    .services-container{
        background-color: rgb(255, 255, 255) !important;
    }

}

@media (min-width: 768px) and (max-width: 1023px){

    .services-container{
        background-color: rgb(255, 255, 255) !important;
    }

}

@media (min-width: 1024px){

    .services-container{
        background-color: rgb(255, 255, 255) !important;
    }

}
