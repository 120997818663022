@media (max-width: 767px){

    #background-video {
        width: 100vw;
        height: auto;
        object-fit: fill;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: -1;
        filter: brightness(45%);
    
    }
    
    .Intro-backimg{
        width: 100vw;
        height: auto;
        object-fit:cover;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: -1;
        filter: brightness(45%);
    
    }
    
    .image-logo {
        max-width: 32vw;
        opacity: 1;
    }
    
    #image-logo-txt {
        font-family: 'Asap';
        color: white !important;
        font-weight: bold;
        font-size: 19px;
    }
    
    .title-img {
        font-family: 'Asap';
        color: white !important;
    
    
    }

    #Intro-Text-container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        text-align: left;
    }

}

@media (min-width: 768px) and (max-width: 1023px){

    #background-video {
        width: 100vw;
        height: 100vh;
        object-fit: fill;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: -1;
        filter: brightness(45%);
    
    }
    
    .Intro-backimg{
        width: 100vw;
        height: 100vh;
        object-fit:cover;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: -1;
        filter: brightness(45%);
    
    }
    
    .image-logo {
        max-width: 32vw;
        opacity: 1;
    }
    
    #image-logo-txt {
        font-family: 'Asap';
        color: white !important;
        font-weight: bold;
        font-size: 19px;
    }
    
    .title-img {
        font-family: 'Asap';
        color: white !important;
    
    
    }

    #Intro-Text-container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        text-align: left;
    }

}

@media (min-width: 1024px){

    #background-video {
        width: 100vw;
        height: auto;
        object-fit: fill;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: -1;
        filter: brightness(45%);
        margin-bottom: 10%;
    
    }
    
    .Intro-backimg{
        width: 100vw;
        height: 100vh;
        object-fit:cover;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: -1;
        filter: brightness(45%);
    
    }
    
    .image-logo {
        max-width: 32vw;
        opacity: 1;
    }
    
    #image-logo-txt {
        font-family: 'Asap';
        color: white !important;
        font-weight: bold;
        font-size: 19px;
    }
    
    .title-img {
        font-family: 'Asap';
        color: white !important;
    
    
    }

    #Intro-Text-container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        text-align: left;
    }

}

