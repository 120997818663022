body.modal-open {
    height: 100vh !important;
    width: 100vw !important;
    overflow-y: scroll !important;
  }
  
  .SuccessPopUp-Header {
      margin: 0% 5%;
      display: flex;
      align-items: center;
      justify-content: space-between;
  }
    
    .SuccessPopUp-Header h2 {
      font-size: 20px;
      font-weight: 700;
      color: rgb(255, 255, 255);
      margin-top: 5%;
    }
    
    .Close-ButtonSuccessPopup {
      margin-left: auto;
      font-size: 30px;
      color: rgb(255, 255, 255);
    }
    
    .Close-ButtonSuccessPopup:hover{
      color: #2B2E30;
    }
    
    .SuccessPopUp-Body{
      margin: 5% 5% 2% 5%;
    }
    
    .SuccessPopUp-Body p {
      text-align: center;
      font-size: 20px;
      font-weight: 700;
      color: #2B2E30;
      margin-left: 5%;
      margin-right: 5%;
    }
    
    .SuccessPopUp-Body span{
      color: rgb(255, 255, 255)
    }
    
    
    
    @media (min-width:790px){
      
      body.modal-open {
        height: 100vh !important;
        width: 100vw !important;
        overflow-y: scroll !important;
      }
      
      .SuccessPopUp-Header {
        margin: 0% 5%;
        float: right;
      }
    
      .SuccessPopUp-Header h2 {
        font-size: 20px;
        font-weight: 600;
        color: rgb(255, 255, 255);
        margin-top: 5%;
      }
    
      .Close-ButtonSuccessPopup {
        margin-left: auto;
        font-size: 35px;
        padding: 0% ;
        font-weight: 700;
        color: rgb(255, 255, 255);
      }
    
      .Close-ButtonSuccessPopup:hover{
        color: #2B2E30;
      }
    
      .SuccessPopUp-Body{
        margin: 0% 0% 0% 0%;
      }
    
      .SuccessPopUp-Body p {
        text-align: center;
        font-size: 25px;
        font-weight: 700;
        color: #2B2E30;
      }
    
      .SuccessPopUp-Body span{
        color: rgb(255, 255, 255)
      }
    
  }