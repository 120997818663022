@media (max-width: 767px){
    .button-lenguage-container{
        display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      height: 90%;
    }
    
    .model-h2{
        font-size: 30px;
        color: #242425;
    }
    
    .Lenguage-button{
        font-size: 25px;
        padding: 2%;
        color: white;
        background-color: #3f51b5;
        border-radius: 5px;
        cursor: pointer;
        border: solid 2px #3f51b5;
        margin-bottom: 5%;
    }
    
    .Lenguage-button:hover{
        color: #3f51b5;
        background-color: white;
        border: solid 2px #3f51b5;
    }
}

@media (min-width: 768px) and (max-width: 1023px){
    .button-lenguage-container{
        display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      height: 90%;
    }
    
    .model-h2{
        font-size: 30px;
        color: #242425;
    }
    
    .Lenguage-button{
        font-size: 25px;
        padding: 2%;
        color: white;
        background-color: #3f51b5;
        border-radius: 5px;
        cursor: pointer;
        border: solid 2px #3f51b5;
        margin-bottom: 5%;
    }
    
    .Lenguage-button:hover{
        color: #3f51b5;
        background-color: white;
        border: solid 2px #3f51b5;
    }
}

@media (min-width: 1024px){
    .button-lenguage-container{
        display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      height: 80%;
    }
    
    .model-h2{
        font-size: 40px;
        color: #242425;
    }
    
    .Lenguage-button{
        font-size: 25px;
        padding: 2%;
        color: white;
        background-color: #3f51b5;
        border-radius: 5px;
        cursor: pointer;
        border: solid 2px #3f51b5;
        margin-bottom: 5%;
    }
    
    .Lenguage-button:hover{
        color: #3f51b5;
        background-color: white;
        border: solid 2px #3f51b5;
    }
}

